import { THREAD_STATE_FILTERS, THREAD_STATES } from 'src/constants';
import { defaultWorkspaceColors } from 'src/lib/theme';

export const getColors = (data) => ({
  primaryColor: data?.primary_color || defaultWorkspaceColors.primaryColor,
  secondaryColor: data?.secondary_color || defaultWorkspaceColors.secondaryColor,
});

export const getCompanyDesign = (company) => ({
  trayIcon: company?.tray_icon,
  dangerIcon: company?.danger_icon,
  warningIcon: company?.warning_icon,
  successIcon: company?.success_icon,
  infoIcon: company?.info_icon,
  desktopIcon: company?.desktop_icon,
  image: company?.image,
  name: company?.name,
  botName: company?.bot_name,
  botImage: company?.bot_icon,
  slackClientId: company?.slack_client_id,
  childCompanyAllowNewContacts: company?.allow_new_contacts,
  parentCompanySalesFlowEnabled: company?.sales_flow_enabled,
  lang: company?.lang,
  botPromptMessages: company?.bot_prompt_message_translations,
  supportType: company?.support_type,
  availability: company?.availability,
  isChildCompany: Boolean(company?.is_client),
  messengerStatus: company?.messenger_status,
  m365Integrated: Boolean(company?.m365_integrated),
  isAutoTitleGenerationEnabled: Boolean(company?.auto_generate_title_enabled),
  askForConfiguration: Boolean(company?.ask_for_configuration),
  threadResponseType: company?.thread_response_type,
  threadResponseTime: company?.thread_response_time,
  ...getColors(company),
});

export const normalizeCompanyInfo = (company) => ({
  id: company?.id,
  parentId: company?.parent_id,
  appId: company?.app_id,
  ...getColors(company),
});

export const normalizeUserInfo = (data) => ({
  id: data?.user_id,
  email: data?.email,
  fullname: data?.full_name,
  integration: {
    chatConfiguration: data?.chat_configuration,
    botInstalled: data?.bot_installed,
    reintegrate: data?.msteams_has_new_configuration === 0 || !!data?.slack_has_new_configuration === 0,
  },
  featureFlags: data?.feature_toggles,
  contactId: data?.contact_id,
  profilePicture: data?.profile_image,
});

export const normalizeUserInfoFromCompany = (data) => ({
  id: data?.user_id,
  contactId: data?.id,
  role: data?.role === 'ADMIN_USER' ? 'admin' : 'user',
});

// TODO: remove twilio normalization after a full migration
export const normalizeTicket = (data) => ({
  id: data?.id,
  number: data?.number || data?.system_id,
  systemId: data?.system_id,
  summary: data?.summary,
  channel: data?.conversation?.name || data?.conversation_channel?.name,
  channelId: data?.conversation?.id || data?.conversation_channel?.id,
  companyName: data?.contact_company_name,
  contact: data?.contact,
  member: data?.member,
  contactName: data?.contact?.full_name || data?.contact_name,
  assignee: data?.assignee,
  assigneeId: data?.member_id,
  status: data?.status_object?.name || data?.status,
  state: data?.state,
  createdAt: data?.created_at?.date ?? data?.created_at,
  timezone: data?.created_at?.timezone, // (using New_York) by default
  updatedAt: data?.updated_at?.date ?? data?.updated_at,
  statusObject: data?.statusObject ?? data?.status_object,
  lastNote: data?.last_note,
  priority: data?.priority,
  contacts: data?.contacts,
  members: data?.members,
  approval: data?.approval,
});

export const normalizeTwilioMessage = (data, contactId) => {
  if (data?.attributes?.is_internal) {
    return null;
  }
  return {
    id: data?.sid,
    attributes: data?.attributes,
    isMine: data?.attributes?.contactId === contactId,
    message: data?.type === 'media' ? data?.media : data?.body,
    timestamp: data?.dateCreated,
    author: data?.author,
    type: data?.type,
    width: data?.attributes?.width,
    height: data?.attributes?.height,
  };
};

export const normalizeAlert = ({ fields, sys }) => ({
  id: sys?.id,
  title: fields?.title,
  description: fields?.description,
  type: fields?.type,
});

// has to match CORE_THREAD_FIELDS
export const normalizeToGraphqlFragment = (data) => {
  if (!data) return null;

  return {
    id: data.id,
    number: data.number,
    system_id: data.system_id,
    summary: data.summary,
    created_at: data.created_at?.date,
    updated_at: data.updated_at?.date,
    assignee: null,
    member_id: null,
    status: data.status,
    state: data.state,
    contact_name: data.contact?.name,
    status_object: data.statusObject,
    conversation_channel: {
      id: data.conversation_channel?.id || null,
      name: data.conversation_channel?.name || null,
      friendly_name: data.conversation_channel?.friendly_name || null,
    },
    last_note: null,
    priority: data.priority,
    contact: {
      ...data.contact,
      full_name: data.contact?.name,
    },
    member: null,
    contacts: null,
    members: null,
    __typename: 'Thread',
  };
};

export const getStateInByThreadState = (state) => {
  return state === THREAD_STATES.DONE ? THREAD_STATE_FILTERS.DONE : THREAD_STATE_FILTERS.ACTIVE;
};
