import React, { useState } from 'react';
import UserAvatar from 'react-native-user-avatar';
import { nameToInitials } from 'src/utils';
import { avatarBgColors } from 'src/constants/theme';
import { getAvatarStyles, getUserPictureStyles } from 'src/components/Common/UserProfilePicture/styles';

const UserProfilePicture = ({ size = 30, url, alt = '', className = '', style }) => {
  const hasProfileImg = url?.length;
  const [isImageLoadFailed, setImageLoadFailed] = useState(false);

  const handleImageLoadFailure = () => {
    setImageLoadFailed(true);
  };

  if (!hasProfileImg || isImageLoadFailed) {
    return (
      <UserAvatar
        textColor="lightslategrey"
        size={size}
        name={nameToInitials(alt?.toUpperCase())}
        bgColors={avatarBgColors}
        style={{ ...getAvatarStyles(), ...style }}
        className={className}
      />
    );
  }

  return (
    <img
      src={url}
      alt={alt}
      className={className}
      style={{ ...getUserPictureStyles(size), ...style }}
      onError={handleImageLoadFailure}
    />
  );
};

export default UserProfilePicture;
