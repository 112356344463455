import React, { Fragment, memo, useEffect, useMemo, useState } from 'react';
import uniq from 'lodash/uniqBy';
import Common from 'src/components/Common';
import reactQueryKeys from 'src/constants/reactQueryKeys';
import EmptyState from '../EmptyState';
import styles from './styles';
import { queryCache } from 'react-query';
import useChatState from 'src/screens/Chat/hooks/useChatState';
import { normalizeTicket } from 'src/utils/normalizer';
import Header from './Header';
import useTranslation from 'src/hooks/useTranslation';
import { GET_MY_THREADS } from 'src/graphql/queries';
import { useGraphQLQuery } from '@Thread-Magic/thread-service-utils';
import ThreadServiceCard from './components/ThreadServiceCard';
import { THREAD_STATE_FILTERS } from 'src/constants';
import { responsiveHeight } from 'src/utils/responsive';

const ThreadServiceList = () => {
  const { translate } = useTranslation();
  const [filterStatus, setFilterStatus] = useState({
    label: translate('header.status.active'),
    value: 0,
  });
  const isFilterDone = filterStatus.value === 1;
  const { chatState, saveTicket } = useChatState();

  const {
    data: threads,
    fetchNextPage,
    isLoading: threadsLoading,
    isFetchingNextPage: nextThreadsFetching,
    hasNextPage: hasNextThreads,
  } = useGraphQLQuery(GET_MY_THREADS(), {
    variables: {
      limit: 20,
      stateIn: isFilterDone ? THREAD_STATE_FILTERS.DONE : THREAD_STATE_FILTERS.ACTIVE,
      sortByCreatedAt: 'DESC',
    },
    dataKey: 'myThreads',
    paginationType: 'cursor',
  });

  const {
    data: approvalThreads,
    fetchNextPage: fetchNextApprovalPage,
    isFetchingNextPage: nextApprovalThreadsFetching,
    hasNextPage: hasNextApprovalThreads,
  } = useGraphQLQuery(GET_MY_THREADS(), {
    variables: {
      limit: 20,
      hasPendingApproval: true,
      stateIn: THREAD_STATE_FILTERS.ACTIVE,
      sortByCreatedAt: 'DESC',
    },
    dataKey: 'myThreads',
    paginationType: 'cursor',
  });

  const sortedThreads = useMemo(() => {
    if (threads?.length) {
      return threads.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    }
    return threads;
  }, [threads]);

  const sortedApprovalThreads = useMemo(() => {
    if (approvalThreads?.length) {
      return approvalThreads.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    }
    return [];
  }, [approvalThreads]);

  useEffect(() => {
    if (threads?.length && !threadsLoading) {
      const queryThreads = queryCache.getQueryData(reactQueryKeys.TICKETS) || [];
      const uniqueThreads = uniq([...threads, ...queryThreads], (i) => i.id);
      queryCache.setQueryData(reactQueryKeys.TICKETS, uniqueThreads);
    }

    const currentLiveThread = threads?.find((el) => el.id === chatState.ticket?.id);

    if (currentLiveThread) {
      saveTicket(normalizeTicket(currentLiveThread));
    }
  }, [threads, threadsLoading]);

  const showFooterSpinner = () => !threadsLoading && nextThreadsFetching && hasNextThreads;
  const approvalThreadsVisible = !!sortedApprovalThreads?.length && !isFilterDone;
  const ContainerComponent = approvalThreadsVisible ? Common.ScrollView : Fragment;

  const ListEmptyComponent = threadsLoading ? <Common.Spinner size="large" /> : <EmptyState />;

  const handleEndReached = () => {
    if (!nextThreadsFetching && hasNextThreads) fetchNextPage({ limit: 20 });
  };

  const handleEndApprovalsReached = () => {
    if (!nextApprovalThreadsFetching && hasNextApprovalThreads) fetchNextApprovalPage({ limit: 20 });
  };

  return (
    <Common.View flexGrow={1} transparent>
      <Header setFilterStatus={setFilterStatus} filterStatus={filterStatus} hasTickets={true} />
      <ContainerComponent {...(approvalThreadsVisible && {style: { flex: 1, maxHeight: responsiveHeight(100) - 216 }})}>
          {approvalThreadsVisible && (
            <>
              <Common.View transparent>
                <Common.Text style={styles.sectionTitle} weight={600} size={14}>
                  {translate('header.section.needsApproval')}
                </Common.Text>
                <Common.FlatList
                  data={sortedApprovalThreads}
                  ListEmptyComponent={ListEmptyComponent}
                  scrollEventThrottle={16}
                  contentContainerStyle={[styles.contentContainer, { paddingBottom: 20 }]}
                  onEndReachedThreshold={0.5}
                  onEndReached={handleEndApprovalsReached}
                  keyExtractor={(item) => item.id?.toString()}
                  renderItem={({ item }) => <ThreadServiceCard item={item} />}
                />
              </Common.View>
              <Common.Text style={styles.sectionTitle} weight={600} size={14}>
                {translate('header.section.allThreads')}
              </Common.Text>
            </>
          )}
          <Common.FlatList
            data={sortedThreads}
            ListEmptyComponent={ListEmptyComponent}
            scrollEventThrottle={16}
            style={[styles.list, approvalThreadsVisible && { flexBasis: 'auto', maxHeight: responsiveHeight(100) }]}
            contentContainerStyle={[styles.contentContainer, threads?.length && { paddingBottom: 60 }]}
            onEndReachedThreshold={0.5}
            onEndReached={handleEndReached}
            keyExtractor={(item) => item.id?.toString()}
            renderItem={({ item }) => <ThreadServiceCard item={item} />}
            ListFooterComponent={showFooterSpinner() && <Common.Spinner />}
          />
      </ContainerComponent>
    </Common.View>
  );
};

export default memo(ThreadServiceList);
