import actionTypes from 'src/constants/actionTypes';

export const saveMessageAction = (message) => ({
  type: actionTypes.CHAT_MESSAGES,
  payload: message,
});

export const editMessageAction = (message) => ({
  type: actionTypes.EDIT_CHAT_MESSAGE,
  payload: message,
});

export const resetChatAction = () => ({
  type: actionTypes.RESET_CHAT,
});

export const setActiveChannelAction = (payload) => ({
  type: actionTypes.SET_ACTIVE_CHANNEL,
  payload,
});

export const setTwilioChannelAction = (payload) => ({
  type: actionTypes.SET_TWILIO_CHANNEL,
  payload,
});

export const incrementNotifCountAction = () => ({
  type: actionTypes.NOTIFICATION_COUNT_INCREMENT,
});

export const setChatTicketAction = (payload) => ({
  type: actionTypes.CHAT_TICKET,
  payload,
});

export const setActiveTicketAction = (payload) => ({
  type: actionTypes.SET_ACTIVE_TICKET,
  payload,
});

export const setNewTicketCreationStateAction = (payload) => ({
  type: actionTypes.CHAT_NEW_TICKET_CREATION_STATE,
  payload,
});
