import { gql } from '@Thread-Magic/thread-service-utils';
import { CORE_THREAD_FIELDS } from 'src/graphql/constants';

export const ON_CONTACT_THREAD_ADDED = () => gql`
  ${CORE_THREAD_FIELDS()}
  subscription ON_CONTACT_THREAD_ADDED {
    onContactThreadAdded {
      event_id
      thread {
        ...CoreThreadFields
      }
    }
  }
`;

export const ON_CONTACT_THREAD_UPDATED = () => gql`
  ${CORE_THREAD_FIELDS()}
  subscription ON_CONTACT_THREAD_UPDATED {
    onThreadUpdated {
      thread {
        ...CoreThreadFields
      }
      delta {
        before {
          name
          value
        }
        after {
          name
          value
        }
      }
    }
  }
`;

export const ON_CONTACT_THREAD_REMOVED = () => gql`
  ${CORE_THREAD_FIELDS()}
  subscription ON_CONTACT_THREAD_REMOVED {
    onContactThreadRemoved {
      thread {
        ...CoreThreadFields
      }
    }
  }
`;

export const ON_CONTACT_THREAD_CLOSED = () => gql`
  ${CORE_THREAD_FIELDS()}
  subscription ON_CONTACT_THREAD_CLOSED {
    onContactThreadClosed {
      thread {
        ...CoreThreadFields
      }
    }
  }
`;

export const ON_CONTACT_THREAD_REOPENED = () => gql`
  ${CORE_THREAD_FIELDS()}
  subscription ON_CONTACT_THREAD_REOPENED {
    onContactThreadReopened {
      thread {
        ...CoreThreadFields
      }
    }
  }
`;

export const ON_CONTACT_THREAD_APPROVAL_REQUESTED = () => gql`
  ${CORE_THREAD_FIELDS()}
  subscription ON_CONTACT_THREAD_APPROVAL_REQUESTED {
    onContactThreadApprovalRequested {
      thread {
        ...CoreThreadFields
      }
    }
  }
`;

export const ON_CONTACT_THREAD_APPROVAL_REMOVED = () => gql`
  ${CORE_THREAD_FIELDS()}
  subscription ON_CONTACT_THREAD_APPROVAL_REMOVED {
    onContactThreadApprovalStatusChanged {
      thread {
        ...CoreThreadFields
      }
    }
  }
`;
