/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import { useEffect } from 'react';
import { getMessengerSource, isElectron, isIOS, validateEmail, validateFullName } from 'src/utils';
import { quickReplyIds, quickReplyMessages } from 'src/constants/messages';
import useProfile from 'src/hooks/useProfile';
import { userFlows } from 'src/constants';
import { reduxPersistor, store } from 'src/redux/store';
import { useTwilioClient } from '@Thread-Magic/chat-utils';
import { reportException } from 'src/utils/monitoring';
import { chatgenieAPI } from 'src/lib/api/chatgenieApi';
import webchatIframe from 'src/utils/webchatIframe';
import { useNotification } from 'src/hooks/useNotification';
import {
  NOTIFICATION_ANALYTICS_ACTIONS,
  notifyDesktop,
  trackDesktopNotification,
} from 'src/utils/desktop-notification';
import { useUserFlowUtils } from './useUserFlowUtils';
import * as Sentry from '@sentry/react-native';
import useTranslation from 'src/hooks/useTranslation';
import { reportDeviceService } from 'src/lib/api/connectWise';
import { useDispatchMapper } from 'src/hooks/actionHooks';
import { updateProfileAction } from 'src/redux/profile/profileActions';
import { getDefaultMessageAttributes } from 'src/screens/Chat/utils';

const isNewUserFlow = (flow) => flow === userFlows.NEW_USER;

export const useNewUserFlow = ({ state, updateState, addBotMessage, addMessage, onTwilioMessage, addMyMessage }) => {
  const { authed, email, flowStarted, flow, connection, companyName, fullname, codeSent } = state;

  const { userInfo, token, loggedIn } = useProfile();
  const { translate, locale } = useTranslation();

  const { initializeClient } = useTwilioClient();
  const { permission, addNotification, removeNotification } = useNotification();
  const { createTicket, validateOTP, sendOTP, channel } = useUserFlowUtils({ state, updateState, addBotMessage });
  const saveProfile = useDispatchMapper(updateProfileAction);

  useEffect(() => {
    if (isNewUserFlow(flow) && !flowStarted) {
      // do not persist new user data
      reduxPersistor.pause();
      if (!email) {
        // bot will ask email of a new user
        addBotMessage(translate('bot.askEmailNewUser'));
      }
      updateState({ flowStarted: true });
    }
    return () => {
      if (channel) {
        channel.unsubscribeNewMessages(onTwilioMessage);
      }
    };
  }, [flowStarted, flow, email]);

  const createNewContact = ({ userEmail, userCompany }) =>
    sendOTP({
      email: userEmail,
      companyName: userCompany,
      connection,
      fullname,
    });

  const getToken = (userEmail) => {
    if (!userEmail) return null;
    const pushChannel = isIOS() ? 'apn' : 'fcm';
    return chatgenieAPI
      .getTwilioToken(userEmail, pushChannel)
      .then(({ data: { data } }) => data?.token)
      .catch((err) => reportException(err));
  };

  const handleOTPVerification = async (code) => {
    try {
      const { token, company } = await validateOTP(code);
      if (token && company) {
        updateState({ inputConfig: { editable: false } });
        if (permission === 'default') {
          addBotMessage(translate('bot.notificationPermission'));
          webchatIframe.requestNotificationPermission();
        }
        addMessage(quickReplyMessages(locale)[quickReplyIds.SELECT_NEW_USER_ACTION]);
        const client = await initializeClient(() => getToken(email));
        if (!client) {
          return;
        }
        client.getUser(email).then((twilioUser) => !twilioUser.friendlyName && twilioUser.updateFriendlyName(fullname));
        client.addListener('messageAdded', async (newMessage) => {
          const channelName = newMessage.conversation.uniqueName;
          const ticketId = channelName.split('-')[1];
          const author = newMessage.attributes?.author || newMessage.author;
          const isInternalMessage = newMessage.attributes?.is_internal;
          const { profile } = store.getState();

          if (isInternalMessage) return;

          if (profile.userInfo.email === newMessage.author || document?.hasFocus()) {
            return;
          }

          const notificationData = {
            title: 'New Message',
            options: {
              body: `${author} replied to Ticket #${ticketId}`,
            },
          };

          if (isElectron()) {
            const notification = await notifyDesktop(notificationData);
            if (notification) {
              trackDesktopNotification({ action: NOTIFICATION_ANALYTICS_ACTIONS.create });
              notification.onclick = () => {
                trackDesktopNotification({ action: NOTIFICATION_ANALYTICS_ACTIONS.click });
                window?.ipc?.sendSync('focus-app');
                removeNotification(ticketId);
              };
            }
          } else {
            notificationData.options.data = { ticketId, channelName, author, uuid: profile.uuid };
            webchatIframe.sendNotification(notificationData);
          }
          addNotification(ticketId);
        });
      }
    } catch (err) {
      Sentry.captureException(err);
      addBotMessage(translate('bot.errorMessage'));
    }
  };

  const handleNewUserMessage = (payload, { attachment: { width, height } = {} } = {}) => {
    if (authed && channel && email) {
      if (!userInfo?.fullname || !fullname) {
        Sentry.captureMessage('Unknown username: useNewUserFlow:handleNewUserMessage', {
          extra: { userInfo, fullname, state },
        });
      }

      return channel.sendMessage(payload, {
        ...getDefaultMessageAttributes(fullname || email),
        ...(width &&
          height && {
            width,
            height,
          }),
      });
    }

    addMyMessage(payload);

    if (!authed && !codeSent) {
      if (!email) {
        if (validateEmail(payload)) {
          updateState({ email: payload });
          addBotMessage(translate('bot.askFullnameNewUser'));
        } else {
          addBotMessage(translate('bot.invalidEmail'));
        }
      } else if (!fullname) {
        if (validateFullName(payload)) {
          updateState({ fullname: payload });
          addBotMessage(translate('bot.askCompanyNewUser'));
        } else {
          addBotMessage(translate('bot.invalidFullName'));
        }
      } else if (!companyName) {
        updateState({ companyName: payload });
        // email is already available in state
        createNewContact({ userCompany: payload, userEmail: email });
      }
    } else if (!authed && codeSent) {
      handleOTPVerification(payload);
    }
  };

  const handleLiveTicketCreation = async (summary) => {
    try {
      const result = await createTicket(summary, onTwilioMessage);
      if (result) {
        addBotMessage(translate('bot.connectingWithAgent'));
        updateState({
          inputConfig: {
            isTicketCreated: true,
          },
        });
      }
    } catch (err) {
      const responseErrorMsg = err.response?.data?.message;
      Sentry.captureException(new Error(`handleLiveTicketCreation ${responseErrorMsg || err.message}`));
      addBotMessage(responseErrorMsg || translate('bot.errorMessage'));
    }
  };

  const handleRequestTicketCreation = async (summary) => {
    try {
      // "sales" flow is used for new users
      const payload = {
        summary,
        initialDescription: summary,
        board: 'sales',
        state: 'active',
      };
      addBotMessage(translate('bot.reachOutLater'));
      await reportDeviceService({ payload });
      if (token && !loggedIn) {
        saveProfile({ loggedIn: true });
      }
    } catch (err) {
      const responseErrorMsg = err.response?.data?.message;
      Sentry.captureException(new Error(`handleRequestTicketCreation ${responseErrorMsg || err.message}`));
      addBotMessage(responseErrorMsg || translate('bot.errorMessage'));
    }
  };

  const handleNewUserAction = (reply) => {
    updateState({ inputConfig: { editable: false } });
    if (reply.value === 'chat_now') {
      handleLiveTicketCreation(reply.label);
    }
    if (reply.value === 'contact_later') {
      handleRequestTicketCreation(reply.label);
    }
  };

  const handleSilentAuthNewUser = (userEmail) => {
    createNewContact({ userEmail, userCompany: companyName });
  };

  return {
    handleNewUserMessage,
    handleNewUserAction,
    handleSilentAuthNewUser,
  };
};
