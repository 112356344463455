import React, { useRef, useState, useEffect } from 'react';
import { captureException } from '@sentry/react-native';
import { useTwilioClient } from '@Thread-Magic/chat-utils';
import useProfile from 'src/hooks/useProfile';
import { chatgenieAPI } from 'src/lib/api/chatgenieApi';
import { isIOS } from 'src/utils';
import { clientStatus } from '@Thread-Magic/chat-utils/lib/useTwilio/twilioChatHelper';
import { match } from 'ts-pattern';

const RETRY_INTERVAL = 10_000;
const MAX_RETRY_COUNT = 10;
const RECONNECTION_WAITING_TIME = 5_000;

const getToken = (email) => {
  const pushChannel = isIOS() ? 'apn' : 'fcm';
  return chatgenieAPI.getTwilioToken(email, pushChannel).then((res) => res?.data?.data?.token);
};

const defaultRetryState = {
  isRetrying: false,
  scheduledRetry: null,
  retryCount: 0,
  retryStarted: false,
};

export default function useTwilioConnection({ enabledAutoConnect = true } = {}) {
  const { initializeClient, client, status, reconnect } = useTwilioClient();
  const [state, setState] = useState(defaultRetryState);
  const { userInfo, companyInfo } = useProfile();
  const companyId = companyInfo?.id;
  const email = userInfo?.email;
  const [isTwilioInitializingFailed, setIsTwilioInitializingFailed] = useState(false);
  const [showWarning, setShowWarning] = useState(false);

  // Refs are used to avoid closure
  const stateRef = useRef(state);
  const statusRef = useRef(clientStatus.disconnected);
  const timeout = useRef();
  const warningTimeout = useRef();

  const handleInitializeClient = async (userEmail) => {
    const initializedClient = await initializeClient(() => getToken(userEmail));
    return initializedClient;
  };

  const handleInitializingException = (err) => {
    const message = err?.response?.data?.message || err?.message || 'Unknown';
    captureException(
      new Error(`Twilio client initialization failed while verifying status: ${message}`, {
        tags: {
          service: 'twilio',
        },
      }),
    );
  };

  const handleUpdateTokenException = (err) => {
    const message = err?.response?.data?.message || err?.message || 'Unknown';
    captureException(new Error(`Twilio error: refreshing access token failed: ${message}`), {
      tags: {
        service: 'twilio',
      },
    });
  };

  const clearTimeouts = () => {
    clearTimeout(timeout.current);
    timeout.current = null;
  };

  const attemptToReconnect = () => {
    clearTimeouts();
    if (!stateRef.current.isRetrying) {
      setState((old) => ({ ...old, isRetrying: true, retryStarted: true }));

      const action = match([statusRef.current])
        .with([clientStatus.denied], () => () => reconnect().catch(handleUpdateTokenException))
        .with([clientStatus.disconnected], () => () => handleInitializeClient(email).catch(handleInitializingException))
        .with(
          [clientStatus.disconnecting],
          () => () => handleInitializeClient(email).catch(handleInitializingException),
        )
        .with([clientStatus.connecting], () => () => new Promise((r) => setTimeout(r, 500)))
        .otherwise(() => () => Promise.resolve());

      action().finally(() => {
        const isRetryCountExceeded = stateRef.current.retryCount + 1 > MAX_RETRY_COUNT;
        setState((old) => ({
          ...old,
          isRetrying: false,
          retryCount: old.retryCount + 1,
          scheduledRetry: isRetryCountExceeded ? null : Date.now() + RETRY_INTERVAL,
        }));

        if (!isRetryCountExceeded && statusRef.current !== clientStatus.connected) {
          timeout.current = setTimeout(attemptToReconnect, RETRY_INTERVAL);
        }
      });
    }
  };

  const checkAndAttemptToReconnect = () => {
    const isRetryCountExceeded = stateRef.current.retryCount > MAX_RETRY_COUNT;
    if (statusRef.current === clientStatus.connected) {
      clearTimeouts();
      setState(defaultRetryState);
    } else if (!timeout.current && !isRetryCountExceeded) {
      // Twilio may restore connection internally
      // waiting for some time before any manual attempt
      timeout.current = setTimeout(attemptToReconnect, RECONNECTION_WAITING_TIME);
    }
  };

  useEffect(() => {
    if (companyId && email && !client && enabledAutoConnect) {
      handleInitializeClient(email).catch((err) => {
        setIsTwilioInitializingFailed(true);
        captureException(
          new Error(`Twilio client initialization failed while starting: ${err?.message}`, {
            tags: {
              service: 'twilio',
            },
          }),
        );
      });
    }
  }, [email, companyId, client]);

  useEffect(() => {
    if (!client || !email || !enabledAutoConnect) {
      return () => {};
    }
    setIsTwilioInitializingFailed(false);

    window.addEventListener('focus', checkAndAttemptToReconnect);
    window.addEventListener('online', checkAndAttemptToReconnect);
    return () => {
      window.removeEventListener('focus', checkAndAttemptToReconnect);
      window.removeEventListener('online', checkAndAttemptToReconnect);
    };
  }, [client, email]);

  useEffect(() => {
    if (client) {
      statusRef.current = status;

      if (status === clientStatus.connected) {
        clearTimeout(warningTimeout.current);
        warningTimeout.current = null;
        setShowWarning(false);
      } else if (!warningTimeout.current) {
        warningTimeout.current = setTimeout(() => {
          setShowWarning(true);
        }, RECONNECTION_WAITING_TIME * 2);
      }

      if (enabledAutoConnect) {
        checkAndAttemptToReconnect();
      }
    }
  }, [status]);

  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  // cleanung up all timers on unmount
  useEffect(() => () => clearTimeouts(), []);

  return {
    client,
    isTwilioInitializingFailed,
    attemptToReconnect,
    showWarning,
  };
}
