import React, { useMemo } from 'react';
import { useDispatchMapper } from 'src/hooks/actionHooks';
import { editMessageAction } from 'src/redux/chat/chatActions';

export default function useChatMessages({ quickReplyActions, chatMessages }) {
  const editMessage = useDispatchMapper(editMessageAction);

  const messages = useMemo(() => {
    if (!chatMessages) return [];

    // sort messages so that quick replies are always at the bottom
    const sortedMessages = chatMessages.filter(Boolean).sort((a, b) => {
      if (a.quickReplies && !a.replied) return 1;
      if (b.quickReplies && !b.replied) return -1;
      return new Date(a.timestamp) - new Date(b.timestamp);
    });

    return sortedMessages;
  }, [chatMessages]);

  const onQuickReply = (replyId, replyValue, replyMessage) => {
    editMessage({
      id: replyMessage.id,
      replied: true,
    });

    const action = quickReplyActions?.[replyId];
    if (action) {
      action(replyValue);
    }
  };

  return {
    messages,
    onQuickReply,
  };
}
