import { gql } from '@Thread-Magic/thread-service-utils';
import { CORE_THREAD_FIELDS } from 'src/graphql/constants';

export const GET_MY_THREADS = () => gql`
  ${CORE_THREAD_FIELDS()}
  query myThreads(
    $limit: Int!
    $stateIn: [String!]
    $cursor: Int
    $sortByCreatedAt: OrderBy
    $sortByUpdatedAt: OrderBy
    $hasPendingApproval: Boolean
  ) {
    myThreads(
      limit: $limit
      filter: { state_in: $stateIn, has_pending_approval: $hasPendingApproval }
      cursor: $cursor
      order: { created_at: $sortByCreatedAt, updated_at: $sortByUpdatedAt }
    ) {
      items {
        ...CoreThreadFields
      }
      meta {
        total
        limit
        offset
        has_more
        next_cursor
      }
    }
  }
`;

export const GET_MENTION_SUGGESTIONS = () => {
  const profileImageGQL = 'profileImage { id path }';

  return gql`
    query GET_MENTION_SUGGESTIONS($search: String, $isInternal: Boolean, $threadId: Int!) {
      mentionAutocomplete(search: $search, isInternal: $isInternal, threadId: $threadId) {
        title
        collection {
          id
          type
          userType
          label
          description
          inThread
          
          ${profileImageGQL}
        }
      }
    }
  `;
};
