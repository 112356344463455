import React, { useEffect, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Common from 'src/components/Common';
import { trackScreenView } from 'src/utils/analytics/GAtracker';
import DevicesList from 'src/components/DevicesList';
import useProfile from 'src/hooks/useProfile';
import useTheme from 'src/hooks/useTheme';
import routeNames from 'src/constants/routeNames';
import { avatarBgColors, colors } from 'src/constants/theme';
import useIntegrationActions from 'src/hooks/useIntegrationActions';
import { isElectron, isWeb, isInsideMSTeams, nameToInitials } from 'src/utils';
import IntegrationButtons from 'src/components/IntegrationButtons';
import { useNotification } from 'src/hooks/useNotification';
import SwitchToggle from 'react-native-switch-toggle';
import webchatIframe from 'src/utils/webchatIframe';
import { promptNotifPermission } from 'src/utils/desktop-notification';
import styles, { switchStyles } from './style';
import LogoutButton from 'src/components/Logout';
import { useDispatchMapper } from 'src/hooks/actionHooks';
import { updateSoundPreferenceAction } from 'src/redux/profile/profileActions';
import useTranslation from 'src/hooks/useTranslation';
import { screenCommonStyles } from 'src/screens/common.styles';
import ProfilePicture from 'src/screens/Account/components/ProfilePicture';
import ErrorBoundary from 'src/components/ErrorBoundary';

const Account = ({ navigation }) => {
  const { userInfo, companyInfo, integration, soundPreferences } = useProfile();
  const updateSoundPreference = useDispatchMapper(updateSoundPreferenceAction);
  const { theme } = useTheme();
  const { connectSlack, connectTeams, state } = useIntegrationActions();
  const { translate } = useTranslation();
  const { permission, enabled, updateNotificationEnabled } = useNotification();
  const { playSoundOnNewMessage, playSoundOnNewThread } = soundPreferences;

  useEffect(() => {
    trackScreenView(routeNames.ACCOUNT);
  }, []);

  const onToggleNotification = (value) => {
    if (value && permission === 'default') {
      if (isElectron()) {
        promptNotifPermission();
      } else {
        webchatIframe.requestNotificationPermission();
      }
    }
    updateNotificationEnabled(value);
  };

  const handleSoundPreference = (sound) => {
    updateSoundPreference({
      [sound]: !soundPreferences[sound],
    });
  };

  useLayoutEffect(() => {
    navigation.setOptions({
      headerTitle: userInfo?.fullname ?? userInfo?.email ?? 'Unknown',
      headerRight: () => <LogoutButton style={styles.logoutBtn} />,
      headerTitleContainerStyle: screenCommonStyles.headerTitleContainerStyle,
    });
  }, [theme]);

  return (
    <Common.View style={styles.accountContainer}>
      <Common.View style={styles.padding}>
        <ErrorBoundary>
          <ProfilePicture />
        </ErrorBoundary>

        <Common.Text size={20} bold>
          {translate('account.title')}
        </Common.Text>

        <Common.View style={styles.greetingContainer}>
          <Common.Text>{translate('common.text.greeting')}</Common.Text>
          <Common.Image source={require('src/assets/icons/greeting.svg')} style={styles.greetingIcon} />
        </Common.View>
        <Common.View style={styles.accountInfo}>
          <Common.Text style={{ marginRight: 5 }}>{translate('account.info.email')}</Common.Text>
          <Common.Text textDecoration="underline">{userInfo?.email}</Common.Text>
        </Common.View>
        <Common.View style={styles.accountInfo}>
          <Common.Text style={{ marginRight: 5 }}>{translate('account.info.workspace')}</Common.Text>
          <Common.Text bold>{companyInfo?.name}</Common.Text>
        </Common.View>
      </Common.View>

      {isWeb() && !isElectron() && !isInsideMSTeams() && (
        <>
          <Common.View style={styles.padding} testID="integration-section">
            <Common.Text size={20} bold>
              {translate('account.integration.title')}
            </Common.Text>
            <IntegrationButtons
              integration={integration}
              loading={state.loading}
              connectSlack={connectSlack}
              connectTeams={connectTeams}
            />
          </Common.View>

          <Common.Text size={20} bold style={styles.padding}>
            {translate('account.notification.title')}
          </Common.Text>
          <Common.Row justifyContent="space-between" alignItems="center" style={styles.padding}>
            <Common.Text size={14} style={styles.notificationText}>
              {translate('account.notification.enable')}
            </Common.Text>
            <SwitchToggle
              backgroundColorOn={theme.primaryColor}
              switchOn={enabled}
              onPress={() => onToggleNotification(!enabled)}
              containerStyle={switchStyles.switchContainer}
              circleStyle={switchStyles.switchCircle}
            />
          </Common.Row>
          {permission === 'denied' && (
            <Common.Text size={14} color={colors.red3} style={styles.padding}>
              {translate('account.notification.permission')}
            </Common.Text>
          )}

          <Common.Text size={20} bold style={styles.padding}>
            {translate('account.sound.title')}
          </Common.Text>
          <Common.Row justifyContent="space-between" alignItems="center" style={styles.padding}>
            <Common.Text size={14} style={styles.notificationText}>
              {translate('account.sound.playSoundOnNewMessage')}
            </Common.Text>
            <SwitchToggle
              backgroundColorOn={theme.primaryColor}
              switchOn={playSoundOnNewMessage}
              onPress={() => handleSoundPreference('playSoundOnNewMessage')}
              containerStyle={switchStyles.switchContainer}
              circleStyle={switchStyles.switchCircle}
            />
          </Common.Row>
          <Common.Row justifyContent="space-between" alignItems="center" style={styles.padding}>
            <Common.Text size={14} style={styles.notificationText}>
              {translate('account.sound.playSoundOnNewThread')}
            </Common.Text>
            <SwitchToggle
              backgroundColorOn={theme.primaryColor}
              switchOn={playSoundOnNewThread}
              onPress={() => handleSoundPreference('playSoundOnNewThread')}
              containerStyle={switchStyles.switchContainer}
              circleStyle={switchStyles.switchCircle}
            />
          </Common.Row>
        </>
      )}

      <DevicesList
        listHeader={
          <Common.Text size={20} bold style={styles.padding}>
            {translate('account.device.title')}
          </Common.Text>
        }
        onClickListItem={(device) => navigation.navigate(routeNames.SINGLE_DEVICE, { device })}
      />

      <Common.View style={styles.appInfo}>
        <Common.Text size={20} bold>
          {translate('account.app.title')}
        </Common.Text>
        <Common.Text color="#999999" style={styles.txtAppId}>
          {companyInfo?.appId}
        </Common.Text>
      </Common.View>
    </Common.View>
  );
};

Account.propTypes = {
  navigation: PropTypes.object.isRequired,
};

export default Account;
