import { useEffect, useMemo, useState } from 'react';
import useProfile from 'src/hooks/useProfile';
import * as Sentry from '@sentry/react-native';
import { normalizeTwilioMessage } from 'src/utils/normalizer';
import { useTwilioClient, useTwilioMessage } from '@Thread-Magic/chat-utils';
import { getDefaultMessageAttributes, scrollBottom } from '../utils';
import useChatState from './useChatState';
import useConnectTwilioChannel from 'src/hooks/useConnectTwilioChannel';
import { getMessengerSource } from 'src/utils';
import { quickReplyIds, quickReplyMessages } from 'src/constants/messages';
import useTranslation from 'src/hooks/useTranslation';
import { getTicketExpirationState } from 'src/screens/Chat/hooks/chat-hooks.utils';
import navigationService from 'src/utils/navigation';
import routeNames from 'src/constants/routeNames';
import useChatMessages from './useChatMessages';

const useExistingTicketFlow = ({ existingTicket }) => {
  const { userInfo } = useProfile();
  const [state, setState] = useState({ loading: false, error: null });
  const {
    setActiveChannel,
    chatState: { activeChannel: channel },
  } = useChatState();

  const { locale } = useTranslation();
  const isTicketOutdated = getTicketExpirationState(existingTicket);
  const { connectChannel } = useConnectTwilioChannel();
  const { client } = useTwilioClient();

  const { messages, isLoading, isFetchingMore, hasMore, loadMore } = useTwilioMessage({
    channel,
    initialLoading: channel?.status === 'joined',
    messageNormalizer: (message) => normalizeTwilioMessage(message, userInfo?.contactId),
    afterNewMessageCallback: () => {
      scrollBottom();
    },
  });

  const joinTwilioChannel = async () => {
    setState({ loading: true, error: null });
    try {
      const connectedChannel = await connectChannel(existingTicket.channel, existingTicket.channelId);
      if (connectedChannel) {
        setActiveChannel(connectedChannel);
        setState({ loading: false, error: null });
      } else {
        setState({ loading: false, error: 'Failed to connect conversation' });
      }
    } catch (err) {
      Sentry.captureMessage(`Can't connect twilio channel: channelName=${existingTicket.channel} - ${err?.message}`);
      Sentry.setContext('Ticket', existingTicket);
      setState({ loading: false, error: 'Failed to fetch messages.' });
    }
  };

  const existingTicketMessages = useMemo(() => {
    if (isTicketOutdated && messages?.length) {
      const quickReplyMessage = quickReplyMessages(locale)[quickReplyIds.TICKET_OUTDATED];
      return [...messages, quickReplyMessage];
    }
    return messages;
  }, [messages, isTicketOutdated]);

  /**
   *
   * @param payload - could be text or formData (attachment) @see /components/Chatbox #handleSubmit
   * @param width - width of attachment
   * @param height - height of attachment
   * @returns {Promise<*>}
   */
  const onSubmit = async (payload, { attachment: { width, height } = {} } = {}) => {
    if (payload && channel) {
      const source = getMessengerSource();

      if (!userInfo?.fullname) {
        Sentry.captureMessage('Unknown username: useExistingTicketFlow:onSubmit', {
          extra: { userInfo },
        });
      }

      return channel.sendMessage(payload, {
        ...getDefaultMessageAttributes(),
        ...(width &&
          height && {
            width,
            height,
          }),
      });
    }
  };

  const handleOutdatedTicket = (replyValue) => {
    if (replyValue?.value === 'createNew') {
      navigationService.goBack();
      navigationService.navigate(routeNames.CHAT);
    } else if (replyValue?.value === 'continueCurrent') {
      navigationService.goBack();
      navigationService.navigate(routeNames.CHAT, { outdatedTicket: existingTicket });
    }
  };

  const quickReplyActions = {
    [quickReplyIds.TICKET_OUTDATED]: handleOutdatedTicket,
  };
  
  const { messages: chatMessages, onQuickReply } = useChatMessages({
    quickReplyActions,
    chatMessages: existingTicketMessages,
  });

  useEffect(() => {
    joinTwilioChannel();
  }, [existingTicket, client]);

  return {
    loading: state.loading || isLoading,
    error: state.error,
    messages: chatMessages,
    onSubmit,
    onQuickReply,
    isFetchingMore,
    hasMore,
    loadMore,
  };
};

export default useExistingTicketFlow;
