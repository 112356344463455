import { createCacheKey, InMemoryCache, reactiveVarClient } from '@Thread-Magic/thread-service-utils';

export const getMyThreadsCacheKey = (variables) => {
  const orderedVariables = [
    ['stateIn', variables.stateIn],
    ['hasPendingApproval', variables.hasPendingApproval],
    ['search', variables.search],
    ['sortByCreatedAt', variables.sortByCreatedAt],
    ['sortByUpdatedAt', variables.sortByUpdatedAt],
  ];
  const cacheKeyFromVariables = createCacheKey(orderedVariables);
  return `${cacheKeyFromVariables}`;
};

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        myThreads: {
          keyArgs: ({ filter, search, order }) => {
            const customCacheKey = getMyThreadsCacheKey({
              stateIn: filter.state_in,
              hasPendingApproval: filter.has_pending_approval,
              sortByCreatedAt: order?.created_at,
              sortByUpdatedAt: order?.updated_at,
              search,
            });
            return `myThreads:${customCacheKey}`;
          },
          merge: (existing = [], incoming, args) => {
            const cursor = args.args.cursor;
            const isFirstPage = !cursor;

            const isAlreadyMergedData = Array.isArray(incoming);
            if (isAlreadyMergedData) {
              return incoming;
            }

            if (isFirstPage) {
              return [incoming];
            }

            const pageIndex = existing.length;
            const copyExisting = existing.concat([]);
            copyExisting[pageIndex] = incoming;
            return copyExisting;
          },
        },
      },
    },
  },
});

export const addToMyThreadsCache = ({ threadToAdd, variables, excludeVariables }) => {
  const apolloClient = reactiveVarClient();

  apolloClient.cache.modify({
    fields: {
      myThreads(cache, { storeFieldName, readField, toReference }) {
        let updatedCache = cache;
        const threadReference = toReference(threadToAdd);
        const cacheKeyMatches = storeFieldName.includes(getMyThreadsCacheKey(variables));
        const excludeCacheKeyMatches = excludeVariables
          ? !storeFieldName.includes(getMyThreadsCacheKey(excludeVariables))
          : true;
        if (cacheKeyMatches && excludeCacheKeyMatches) {
          const [firstPage, ...restPages] = cache;
          const isAlreadyInCache = !!cache.some((page) =>
            page.items.some((el) => {
              const threadId = readField('id', el);
              return threadId === threadToAdd.id;
            }),
          );
          if (!isAlreadyInCache) {
            const extendedFirstPage = {
              ...firstPage,
              items: [threadReference, ...firstPage.items],
            };
            updatedCache = [extendedFirstPage, ...restPages];
          }
        }

        return updatedCache;
      },
    },
  });
};

export const removeFromMyThreadsCache = ({ threadToRemove, variables, excludeVariables }) => {
  const apolloClient = reactiveVarClient();

  apolloClient.cache.modify({
    fields: {
      myThreads(cache, { storeFieldName, readField }) {
        let updatedCache = cache;
        const cacheKeyMatches = storeFieldName.includes(getMyThreadsCacheKey(variables));
        const excludeCacheKeyMatches = excludeVariables
          ? !storeFieldName.includes(getMyThreadsCacheKey(excludeVariables))
          : true;
        if (cacheKeyMatches && excludeCacheKeyMatches) {
          updatedCache = updatedCache.map((page) => ({
            ...page,
            items: page.items.filter((el) => readField('id', el) !== threadToRemove.id),
          }));
        }

        return updatedCache;
      },
    },
  });
};
