import { omitBy, isNil } from 'lodash';
import { useEffect } from 'react';
import { queryCache } from 'react-query';
import { userFlows } from 'src/constants';
import reactQueryKeys from 'src/constants/reactQueryKeys';
import routeNames from 'src/constants/routeNames';
import { updateAuthAction } from 'src/redux/auth/actions';
import { setActiveTicketAction } from 'src/redux/chat/chatActions';
import { removeTicketNotificationAction, saveNotificationPermissionAction } from 'src/redux/notifications/actions';
import { updateProfileAction } from 'src/redux/profile/profileActions';
import { reduxPersistor, store } from 'src/redux/store';
import { isWeb } from 'src/utils';
import { trackEvent } from 'src/utils/analytics/GAtracker';
import navigationService from 'src/utils/navigation';
import { normalizeTicket } from 'src/utils/normalizer';
import { webchatPayloadTypes } from 'src/utils/webchatIframe';
import Authentication from 'src/utils/auth';

const eventHandlers = {
  // This event is triggered when the user customizes Messenger design/settings in Admin Panel
  UPDATE_THEME_PREVIEW: (event) => {
    const payload = event?.data?.payload;
    if (payload) {
      // Temporarily pause the Redux persistor to prevent saving temporary theme changes
      // The user might be experimenting with colors and settings without intending to save them
      reduxPersistor?.pause();
      store?.dispatch(
        updateProfileAction({
          companyInfo: omitBy(
            {
              image: payload?.image,
              name: payload?.name,
              botName: payload?.botName,
              botImage: payload?.botImage,
              primaryColor: payload?.primaryColor,
              secondaryColor: payload?.secondaryColor,
              supportType: payload?.supportType,
            },
            isNil,
          ),
        }),
      );
      reduxPersistor?.persist();
    }
  },
  NEW_ATTRIBUTES: (event) => {
    const payload = event?.data?.payload;
    if (payload?.fullname && payload?.companyName && payload?.email) {
      store?.dispatch(
        updateAuthAction({
          fullname: payload.fullname,
          companyName: payload.companyName,
          email: payload.email,
          flow: userFlows.EXISTING_USER,
          isPortalIntegration: true,
        }),
      );
    }
  },
  [webchatPayloadTypes.NOTIFICATION_CLICK]: (event) => {
    const payload = event?.data?.payload || {};
    if (payload.ticketId) {
      const tickets = queryCache.getQueryData(reactQueryKeys.TICKETS) || [];
      const notificationTicket = tickets.find((el) => el.id?.toString() === payload.ticketId);
      if (notificationTicket) {
        const normalized = normalizeTicket(notificationTicket);
        store.dispatch(setActiveTicketAction(normalized));
        store.dispatch(removeTicketNotificationAction(payload.ticketId));
        const { messages } = store.getState().chat;
        if (messages.length) {
          navigationService.navigateWithReplace(routeNames.CHAT, { ticket: normalized });
        } else {
          navigationService.navigate(routeNames.CHAT, { ticket: normalized });
        }
      }
    }
  },
  [webchatPayloadTypes.NOTIFICATION_PERMISSION]: (event) => {
    trackEvent({
      category: 'Notifications',
      action: 'notification_permission',
      label: `web-${window.navigator.platform}`,
      value: payload === 'granted' ? 1 : 0,
    });
    const payload = event?.data?.payload;
    store.dispatch(saveNotificationPermissionAction(payload));
  },
  // programmatic logout support "window.chatgenie.logout"
  [webchatPayloadTypes.LOGOUT]: () => {
    Authentication.logout();
  },
};

export const useInitializers = () => {
  const eventListener = (event) => {
    eventHandlers?.[event?.data?.type]?.(event);
  };

  useEffect(() => {
    if (isWeb()) {
      window?.addEventListener('message', eventListener, '*');
      return () => {
        window?.removeEventListener('message', eventListener, '*');
      };
    }
    return () => {};
  }, []);
};
