import React, { useEffect, useState } from 'react';
import { AppRegistry } from 'react-native';
import * as Sentry from '@sentry/react-native';
import Config from 'react-native-config';
import Axios from 'axios';
import { app, authentication } from '@microsoft/teams-js';
import { useQueryValues } from '../../../../shared/src/hooks/useQueryValues';
import App from '../../../../shared/src/App';
import { store } from '../../../../shared/src/redux/store';
import { updateProfileAction } from '../../../../shared/src/redux/profile/profileActions';
import { chatgenieAPI } from '../../../../shared/src/lib/api/chatgenieApi';
import { MESSENGER_TEAMS_LAYOUT_OPTIONS, userFlows } from '../../../../shared/src/constants';
import { normalizeUserInfoFromCompany } from '../../../../shared/src/utils/normalizer';
import AuthError from './AuthError';
import './index.css';

function validURL(str) {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // fragment locator
  return !!pattern.test(str);
}

const isYouTubeFrame = (str) => str.includes('www.youtube.com/embed');

const fetchAndSelectCompany = async () => {
  const response = await chatgenieAPI.getCompanies();
  const companies = response?.data?.filter((company) => company.is_contact) || [];
  const profilePayload = {
    flow: userFlows.EXISTING_USER,
    loggedIn: true,
  };

  if (companies.length === 1) {
    profilePayload.companyInfo = {
      id: companies[0].id,
      parentId: companies[0].parent_id,
      appId: companies[0].app_id,
    };
    profilePayload.userInfo = {
      ...(store.getState().profile?.userInfo || {}),
      ...normalizeUserInfoFromCompany(companies[0].contacts?.[0]),
    };
  }

  return profilePayload;
};

function loginWithMSTeams() {
  return authentication.authenticate({
    url: `${Config.BASE_DOMAIN}/auth-start`,
    width: 600,
    height: 535,
  });
}

function exchangeAuthCodeForToken(authCode) {
  return Axios.post(`${Config.CHATGENIE_API_URL}/oauth/token`, {
    code: authCode,
    redirect_uri: `${Config.BASE_DOMAIN}/auth-end`,
  }).then((res) => res.data.data);
}

async function initMessenger(appId, accessToken, refreshToken = null) {
  store.dispatch(
    updateProfileAction({
      token: accessToken,
      refreshToken: refreshToken,
      companyInfo: { appId },
    }),
  );

  const state = await fetchAndSelectCompany();
  store.dispatch(updateProfileAction(state));
}

function getSupportPageData(appId) {
  return Axios.get(`${Config.CHATGENIE_API_URL}/workspaces/${appId}/service-page`).then((res) => res?.data?.data);
}

function getCompanyDesign(appId) {
  return Axios.get(`${Config.CHATGENIE_API_URL}/workspaces/${appId}/design`).then((res) => res?.data?.data);
}

function renderMessenger() {
  AppRegistry.registerComponent('Chatgenie', () => App);
  AppRegistry.runApplication('Chatgenie', {
    rootTag: document.getElementById('messenger'),
  });
}

const CallToAction = ({ item, primaryColor }) => {
  const preHref = item.properties.type === 'phone' ? 'tel:' : item.properties.type === 'email' ? 'mailto:' : '';
  return isYouTubeFrame(item.properties.value) ? (
    <iframe
      src={item.properties.value}
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    ></iframe>
  ) : (
    <a
      target="_blank"
      href={`${preHref}${item.properties.value}`}
      className="button"
      style={{
        background: primaryColor,
      }}
    >
      {item.properties.label}
    </a>
  );
};

const SupportLanding = () => {
  const queries = useQueryValues();
  const [data, setData] = useState();
  const [supportPageError, setSupportPageError] = useState();
  const [primaryColor, setPrimaryColor] = useState();
  const [errorMessage, setErrorMessage] = useState('');

  const chatPosition = data?.chat_position ?? MESSENGER_TEAMS_LAYOUT_OPTIONS.MESSENGER_RIGHT;
  const leftOptions = [
    MESSENGER_TEAMS_LAYOUT_OPTIONS.MESSENGER_LEFT,
    MESSENGER_TEAMS_LAYOUT_OPTIONS.MESSENGER_BIG_LEFT,
  ];
  const bigOptions = [
    MESSENGER_TEAMS_LAYOUT_OPTIONS.MESSENGER_BIG_LEFT,
    MESSENGER_TEAMS_LAYOUT_OPTIONS.MESSENGER_BIG_RIGHT,
  ];

  const isChatOnLeft = leftOptions.includes(chatPosition);
  const isBigChat = bigOptions.includes(chatPosition);

  async function init() {
    try {
      setErrorMessage('');

      const code = await loginWithMSTeams();
      const data = await exchangeAuthCodeForToken(code);
      // TODO: why refresh token is not returned after redirect?
      // https://community.auth0.com/t/no-refreshtoken-being-returned-in-social-authentication/8999
      initMessenger(queries.appId, data.access_token);
      renderMessenger();
    } catch (error) {
      const context = await app.getContext();

      setErrorMessage(error.message);
      console.warn(`Error in landing while authenticating through teams: ${error.message}`);

      Sentry.captureException(new Error(`Error in landing while authenticating through teams: ${error.message}`), {
        tags: {
          feature: 'landing',
          teamsUserEmail: context.user.loginHint,
          teamsTenantId: context.user.tenant.id,
        },
      });
    }
  }

  useEffect(() => {
    app.initialize();
    app.notifySuccess();

    // We should get logged in and token states from auth0 instead
    const isLoggedIn = store.getState().profile?.loggedIn;
    const token = store.getState().profile?.token;

    if (isLoggedIn && token) {
      console.log('[Support Messenger]: User auth present');
      // Since there could be multiple apps in the same tenant
      // we need to make sure we provide correct app id in order
      // for Messenger to load correct company from correct workspace
      initMessenger(queries.appId, token);
      renderMessenger();
    } else {
      console.log('[Support Messenger]: User auth expired');
      init();
    }
  }, []);

  useEffect(() => {
    getSupportPageData(queries?.appId)
      .then((res) => setData(res))
      .catch((err) => {
        setSupportPageError(err.response?.data?.message || err.message || 'Something went wrong while fetching data');
        Sentry.captureException(err, {
          extra: err.response?.data?.message,
        });
      });
    getCompanyDesign(queries?.appId)
      .then((res) => setPrimaryColor(res?.primary_color))
      .catch((err) =>
        Sentry.captureException(err, {
          extra: err.response?.data?.message,
        }),
      );
  }, []);

  return (
    <div className="support-landing">
      <div
        className="header"
        {...(data && {
          style: {
            backgroundImage: `url(${data.featured_image})`,
          },
        })}
      ></div>
      <div className={`s-container ${isChatOnLeft ? 'reverse-rows' : ''}`}>
        <div className={`column-1 ${isBigChat ? 'small' : ''}`}>
          {supportPageError && (
            <div className="error-message">
              <p className="error-message">{supportPageError}</p>
              <p className="error-message">
                Please make sure the app is integrated with correct company in the Admin Portal
              </p>
              <p className="error-message">{JSON.stringify(queries)}</p>
            </div>
          )}
          {data &&
            data.blocks.map((item, index) => {
              const isEmojiIcon = !validURL(item.properties.icon);
              return (
                <div className={`item ${isYouTubeFrame(item.properties.value) ? 'item-video' : ''}`} key={index}>
                  <div className="item-content">
                    <div className={`icon ${isEmojiIcon ? 'emoji' : ''}`}>
                      {isEmojiIcon ? (
                        item.properties.icon
                      ) : (
                        <img src={item.properties.icon} alt={item.properties.title} width="45" />
                      )}
                    </div>
                    <div className={`title ${!item.properties.description ? 'centered' : ''}`}>
                      <h2>{item.properties.title}</h2>
                      {item.properties.description && <p>{item.properties.description}</p>}
                    </div>
                  </div>
                  <div className="cta">
                    <CallToAction item={item} primaryColor={primaryColor} />
                  </div>
                </div>
              );
            })}
        </div>
        <div className={`column-2 ${isBigChat ? 'big' : ''}`}>
          <div id="messenger" data-mount-type="teams">
            {errorMessage && <AuthError onClickSignin={init} message={errorMessage} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupportLanding;
