import React, { useState } from 'react';
import UserAvatar from 'react-native-user-avatar';
import { useDropzone } from 'react-dropzone';
import styles, { getProfilePictureOverlayStyles } from 'src/screens/Account/style';
import { nameToInitials } from 'src/utils';
import { avatarBgColors } from 'src/constants/theme';
import useProfile from 'src/hooks/useProfile';
import { Pressable } from 'react-native';
import Hoverable from 'src/components/Hoverable';
import { FIEdit } from 'src/assets/icons';
import { getImagePreview } from 'src/components/Chatbox/MessageInput/Textarea/utils';
import { chatgenieAPI } from 'src/lib/api/chatgenieApi';
import { useDispatchMapper } from 'src/hooks/actionHooks';
import { updateProfileAction } from 'src/redux/profile/profileActions';
import Common from 'src/components/Common';
import useTranslation from 'src/hooks/useTranslation';
import { reactiveVarClient } from '@Thread-Magic/thread-service-utils';
import { Alerts } from 'src/components/ConnectionIndicator';

const MAX_PROFILE_PICTURE_SIZE = 5 * 1024 * 1024; // 5MB

const ProfilePicture = () => {
  const { userInfo } = useProfile();
  const updateProfile = useDispatchMapper(updateProfileAction);
  const [attachment, setAttachment] = useState();
  const { translate } = useTranslation();
  const removeProfilePictureText = translate('account.profilePic.remove');
  const apolloClient = reactiveVarClient();
  const [fileError, setFileError] = useState('');

  const updateProfilePicture = async (file) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      const photoRes = await chatgenieAPI.uploadAttachmentToS3(formData);
      const s3AttachmentId = photoRes.data.data.id;

      await chatgenieAPI.saveProfilePicture(userInfo.contactId, {
        profile_picture_id: s3AttachmentId,
      });

      updateProfile({
        userInfo: {
          ...userInfo,
          profilePicture: {
            ...userInfo.profilePicture,
            ...photoRes.data.data,
          },
        },
      });
      setAttachment(null);
    } catch (err) {
      console.log('Failed to upload profile picture', err.response?.data || err.message);
      setFileError('Supported types: jpg, png, svg, webp max size: 5MB');
    }
  };

  const removeProfilePicture = async () => {
    if (userInfo.profilePicture) {
      await chatgenieAPI.removeProfilePicture(userInfo.contactId);
      updateProfile({
        userInfo: {
          ...userInfo,
          profilePicture: null,
        },
      });
    }
    setAttachment(null);
  };

  const handleProfilePictureChange = async (files, event) => {
    const file = files?.length ? files[0] : null;
    setFileError('');

    if (file) {
      const preview = await getImagePreview(file);
      setAttachment({
        file,
        preview,
        name: file.name,
      });

      updateProfilePicture(file);
      apolloClient.reFetchObservableQueries();
    }
  };

  const handleProfilePictureReject = (files) => {
    try {
      const erroredFile = Array.isArray(files) ? files[0] : null;
      const { code: errorCode, message: errorMessage } = erroredFile?.errors[0];

      if (errorCode === 'file-too-large') {
        setFileError("Maximum image size can't exceed 5MB");
      } else {
        setFileError(errorMessage);
      }
    } catch (err) {
      setFileError(`Error while uploading a profile picture ${err.message}`);
    }
  };

  const {
    getRootProps,
    getInputProps,
    isDragAccept,
    open: openFilePicker,
  } = useDropzone({
    onDropAccepted: handleProfilePictureChange,
    onDropRejected: handleProfilePictureReject,
    noClick: true,
    noKeyboard: true,
    maxSize: MAX_PROFILE_PICTURE_SIZE,
    accept: { 'image/*': ['.png', '.jpeg', '.jpg', '.svg'] },
    maxFiles: 1,
    // disabled: !canSendAttachment,
  });

  const renderProfilePic = () => {
    if (attachment) return <img src={attachment.preview} alt={attachment.name} style={styles.profilePic} />;

    if (userInfo.profilePicture)
      return <img src={userInfo.profilePicture.path} alt={userInfo.fullname} style={styles.profilePic} />;

    return (
      <UserAvatar
        size={35}
        textColor="lightslategrey"
        name={nameToInitials(userInfo?.fullname)}
        bgColors={avatarBgColors}
        style={styles.profilePlaceHolderAvatar}
      />
    );
  };

  return (
    <div {...getRootProps()} style={styles.profilePictureWrapper}>
      {fileError && <Alerts title={fileError} />}
      <Hoverable>
        {(isHovered) => (
          <Pressable onPress={openFilePicker}>
            <div style={getProfilePictureOverlayStyles(isHovered || isDragAccept)}>
              <FIEdit />
            </div>

            {renderProfilePic()}
          </Pressable>
        )}
      </Hoverable>

      <input {...getInputProps()} />

      {userInfo.profilePicture && (
        <Common.TouchableOpacity style={styles.removeProfilePic} onPress={removeProfilePicture}>
          <Common.Row flexGrow={1} alignItems="center" justifyContent="center">
            <Common.Text>{removeProfilePictureText}</Common.Text>
          </Common.Row>
        </Common.TouchableOpacity>
      )}
    </div>
  );
};

export default ProfilePicture;
