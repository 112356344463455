import React from 'react';
import useTwilioConnection from './hooks/useTwilioConnection';
import { Alerts } from './index';

const TwilioConnectionIndicator = () => {
  const { showWarning, attemptToReconnect } = useTwilioConnection({ enabledAutoConnect: false });

  if (showWarning) {
    return <Alerts onPress={attemptToReconnect} />;
  }

  return '';
};

export default TwilioConnectionIndicator;
