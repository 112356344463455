import React from 'react';
import ReactDOM from 'react-dom';
import { AppRegistry } from 'react-native';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import Modal from 'modal-react-native-web';
import SupportLanding from './components/SupportLanding';
import SupportBot from './components/SupportBot';
import AuthStart from './components/SupportLanding/AuthStart';
import AuthEnd from './components/SupportLanding/AuthEnd';
import App, { ReduxProvider } from '../../shared/src/App';
import { isDevelopment, injectDefaultStyles, injectStyles, isPWAApp, isElectron } from './utils';
import inMemoryLocalStorage from './wrappers/inMemoryLocalStorage';
import * as serviceWorker from './serviceWorker';
import './index.css';

try {
  if (!window.localStorage) {
    Object.defineProperty(window, 'localStorage', {
      value: inMemoryLocalStorage,
      configurable: true,
      enumerable: true,
      writable: true,
    });
  }
} catch (err) {
  Object.defineProperty(window, 'localStorage', {
    value: inMemoryLocalStorage,
    configurable: true,
    enumerable: true,
    writable: true,
  });
}

if (!isDevelopment()) {
  const dsn = isElectron()
    ? process.env?.REACT_APP_SENTRY_ELECTRON_DSN
    : process.env?.REACT_APP_SENTRY_WIDGET_DSN;

    const release = isElectron() ? require('../../desktop/package.json')?.version : require('../package.json')?.version;
    Sentry.init({
      dsn,
      release,
      sendDefaultPii: true,
      // https://docs.sentry.io/platforms/javascript/configuration/sampling/
      sampleRate: 0.5,
      tracesSampleRate: 0.2,
      integrations: [new Integrations.BrowserTracing()],
      environment: process.env.REACT_APP_ENV,
      ignoreErrors: [
        'Non-Error exception captured',
        'Non-Error promise rejection captured with value: Object Not Found Matching',
      ],
    });
}

Modal.setAppElement('#app_modal');

injectDefaultStyles();

// Initialize
const path = window.location.pathname;

if (path === '/landing') {
  ReactDOM.render(<SupportLanding />, document.getElementById('root'));
} else if (path === '/support-bot') {
  ReactDOM.render(
    <ReduxProvider>
      <SupportBot />
    </ReduxProvider>,
    document.getElementById('root'),
  );
} else if (path === '/auth-start') {
  ReactDOM.render(<AuthStart />, document.getElementById('root'));
} else if (path === '/auth-end') {
  ReactDOM.render(
    <ReduxProvider>
      <AuthEnd />
    </ReduxProvider>,
    document.getElementById('root'),
  );
} else {
  // https://necolas.github.io/react-native-web/docs/setup/#root-element
  injectStyles(`
body {
  overflow: hidden;
  border-radius: 10px;
}
#root {
  display: flex;
}`);

  AppRegistry.registerComponent('Chatgenie', () => App);
  AppRegistry.runApplication('Chatgenie', {
    rootTag: document.getElementById('root'),
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

if (isPWAApp()) {
  serviceWorker.register({
    onUpdate: async (registration) => {
      // We want to run this code only if we detect a new service worker is
      // waiting to be activated.
      // Details about it: https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle
      if (registration && registration.waiting) {
        await registration.unregister();
        // Makes Workbox call skipWaiting()
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        // Once the service worker is unregistered, we can reload the page to let
        // the browser download a fresh copy of our app (invalidating the cache)
        window.location.reload();
      }
    },
  });
} else {
  serviceWorker.unregister();
}
