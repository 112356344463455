import { colors } from 'src/constants/theme';

export const styles = {
  container: {
    position: 'relative',
    backgroundColor: colors.white,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  avatar: {
    height: 60,
    width: 60,
    position: 'absolute',
    top: -30,
  },
  title: {
    fontSize: 14,
    fontWeight: '600',
    textAlign: 'center',
    marginTop: 8,
  },
  desc: {
    fontSize: 14,
    fontWeight: '400',
    marginTop: 34,
    textAlign: 'center',
    color: colors.grey3,
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: 8,
    marginTop: 10,
    paddingHorizontal: 12,
    width: '100%',
  },
  action: {
    height: 44,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    backgroundColor: colors.red3,
    flex: 1,
    borderRadius: 4,
  },
  actionOutlined: {
    backgroundColor: colors.white,
    borderWidth: 1,
    borderColor: colors.red3,
  },
  outlinedButtonText: {
    color: colors.red3,
    fontSize: 14,
    fontWeight: '600',
  },
  buttonText: {
    color: colors.white,
    fontSize: 14,
    fontWeight: '600',
  },
};
