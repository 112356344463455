import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTwilioClient } from '@Thread-Magic/chat-utils';
import { colors } from 'src/constants/theme';
import { PoweredByIcon, PoweredByIconFr } from 'src/assets/icons';
import MessageInput from './MessageInput';
import Message from './Message';
import Common from '../Common';
import styles from './style';
import QuickReplies from './QuickReplies';
import MessageList from './MessageList';
import AvailabilityBanner from 'src/screens/Now/components/AvailabilityBanner';
import { trackEvent } from 'src/utils/analytics/GAtracker';
import useChatState from 'src/screens/Chat/hooks/useChatState';
import TypingIndicator from './TypingIndicator';
import LiveThreadStatusStepper from './LiveThreadStatusStepper';
import useProfile from 'src/hooks/useProfile';
import useTranslation from 'src/hooks/useTranslation';
import { LIVE_CHAT_PREFIX } from 'src/utils';
import { getTicketParticipants } from 'src/utils/mention.utils';
import TwilioConnectionIndicator from '../ConnectionIndicator/TwilioConnectionIndicator';
import ApprovalTile from './ApprovalTile';

const Chatbox = ({
  error,
  loading,
  messages,
  messagesRef,
  onSendMessage,
  inputDisabled,
  inputPlaceHolderText,
  inputConfig,
  onQuickReply,
  hasMore,
  isFetchingMore,
  loadMore,
  renderEmptyMessage,
  isNewTicket,
  isNewRequest,
  isAuthChat, // thread created by newly signed-up contact at Auth screen
}) => {
  const [attachments, setAttachments] = useState([]);
  const { userInfo } = useProfile();
  const { client } = useTwilioClient();
  const [isApprovalTileVisible, setIsApprovalTileVisible] = useState(false);
  const {
    chatState: { twilioChannel, activeChannel, activeTicket, ticket },
    saveTicket,
    resetChat,
  } = useChatState();
  // ticket is newly opened thread
  // activeTicket is already existing thread
  const currentTicket = activeTicket || ticket;
  // similarly, twilioChannel is newly opened
  // activeChannel is from existing thread
  const channel = useMemo(
    () => (isNewTicket ? twilioChannel : activeChannel),
    [isNewTicket, activeChannel, twilioChannel],
  );
  const { translate, locale } = useTranslation();

  const hasMemberMessage = messages.length && messages.some((el) => el?.attributes?.user_type === 'member');

  // means we have a newly created thread or existing thread
  // otherwise it will be empty screen with "New Request" or "New Chat"
  const chatboxHasThread = channel && currentTicket;

  // request thread is also referred as non-live thread
  // created in "New Request" flow
  const isRequestThread = useMemo(() => {
    const liveSummary = currentTicket?.summary?.includes(LIVE_CHAT_PREFIX);
    return isNewRequest || !liveSummary;
  }, [currentTicket?.summary, isNewRequest]);

  const [showProgressAtTop, setShowProgressAtTop] = useState(Boolean(currentTicket?.assignee));
  const handleChangeAttachments = (newAttachments, attachmentToRemove = {}) => {
    const filtered = attachments.filter(
      (el) => !(newAttachments.find((file) => file.id === el.id) || attachmentToRemove.id === el.id),
    );
    setAttachments([...filtered, ...newAttachments]);
  };

  const handleSubmit = async (text) => {
    if (attachments.length) {
      trackEvent({
        category: 'Attachment',
        action: `send`,
      });
      const savedAttachments = attachments;
      setAttachments([]);
      // For new ticket, attachmentsToBeSent will be stored to be sent after ticket is created
      if (!currentTicket && !inputConfig?.isTicketCreated) {
        const attachmentsToBeSent = savedAttachments.map((attachment) => {
          const formData = new FormData();
          formData.append('file', attachment.file);
          return { message: formData, type: 'media', attachment };
        });
        onSendMessage(text, { attachmentsToBeSent });
        return;
      }

      if (text) {
        await onSendMessage(text);
      }
      Promise.all(
        savedAttachments.map((attachment) => {
          const formData = new FormData();
          formData.append('file', attachment.file);
          return onSendMessage(formData, {
            type: 'media',
            attachment,
          }).catch((err) => console.log('err', err));
        }),
      );
      return;
    }
    onSendMessage(text);
  };

  const participants = getTicketParticipants(currentTicket || {});

  const renderMessage = ({ item, index }) =>
    item && (
      <Message
        key={item.id}
        message={item}
        index={index}
        messagesLength={messages?.length - 1}
        participants={participants}
      />
    );

  const lastMessage = messages[messages.length - 1];
  const quickReplies = !lastMessage?.replied ? lastMessage?.quickReplies : null;
  const isInputDisabled = inputDisabled || (!!quickReplies && !quickReplies?.allowChatInput);
  const placeholderText =
    !!quickReplies && !quickReplies?.allowChatInput
      ? translate('ticket.chatBox.optionPlaceholder')
      : inputPlaceHolderText;

  const approval = currentTicket?.approval;
  const isStatusStepperVisible = chatboxHasThread && !isApprovalTileVisible;

  const sendTypingSignal = () => channel && channel.typing();

  useEffect(() => {
    const canApprove =
      approval?.status === 'pending' && approval?.approvers?.some((approver) => approver.id === userInfo.contactId);
    setIsApprovalTileVisible(canApprove);
  }, [approval?.status])

  useEffect(() => {
    return () => {
      // reset chat state on unmount -> for new ticket or existing ticket
      resetChat();
    };
  }, []);

  useEffect(() => {
    // syncing ticket data in new user flow
    if (client && isAuthChat) {
      const messageUpdateHandler = ({ message, updateReasons }) => {
        const updatedTicket = message?.attributes?.ticket;
        if (updateReasons.includes('attributes') && updatedTicket && updatedTicket.id === currentTicket?.id) {
          saveTicket(updatedTicket);
        }
      };
      client.addListener('messageUpdated', messageUpdateHandler);
      return () => {
        client.removeListener('messageUpdated', messageUpdateHandler);
      };
    }
  }, [client, currentTicket, isAuthChat]);

  return (
    <Common.View style={styles.wrapper}>
      {isStatusStepperVisible && showProgressAtTop && (
        <LiveThreadStatusStepper
          withDetails={false}
          setShowProgressAtTop={setShowProgressAtTop}
          ticket={currentTicket}
          channel={channel}
          hasMemberMessage={hasMemberMessage}
          isRequestThread={isRequestThread}
        />
      )}
      <TwilioConnectionIndicator />
      {!isNewTicket && <AvailabilityBanner />}
      <MessageList
        messages={messages}
        renderListItem={renderMessage}
        hasMore={hasMore}
        loadMore={loadMore}
        loading={loading}
        renderEmptyMessage={renderEmptyMessage}
        error={error}
        isFetchingMore={isFetchingMore}
        messagesRef={messagesRef}
      />
      {quickReplies ? (
        <QuickReplies data={quickReplies} onQuickReply={(id, reply) => onQuickReply(id, reply, lastMessage)} />
      ) : null}
      <TypingIndicator channel={channel}>
        {locale === 'fr' ? <PoweredByIconFr fill={colors.grey7} /> : <PoweredByIcon fill={colors.grey7} />}
      </TypingIndicator>
      {isStatusStepperVisible && !showProgressAtTop && (
        <LiveThreadStatusStepper
          setShowProgressAtTop={setShowProgressAtTop}
          ticket={currentTicket}
          channel={channel}
          hasMemberMessage={hasMemberMessage}
          isRequestThread={isRequestThread}
        />
      )}
      {isApprovalTileVisible && <ApprovalTile approvalName={approval.name} channel={channel} onClose={() => setIsApprovalTileVisible(false)} />}
      <MessageInput
        onSubmit={handleSubmit}
        inputConfig={inputConfig}
        disabled={isInputDisabled}
        placeholderText={placeholderText}
        attachments={attachments}
        isAuthChat={isAuthChat}
        onChangeAttachments={handleChangeAttachments}
        onInputValueChange={sendTypingSignal}
        isNewTicket={isNewTicket}
      />
    </Common.View>
  );
};

Chatbox.defaultProps = {
  inputPlaceHolderText: null,
  inputDisabled: false,
  loading: false,
  error: null,
  onQuickReply: () => {},
  inputConfig: null,
  renderEmptyMessage: () => null,
  hasMore: false,
  isFetchingMore: false,
  loadMore: () => {},
  isNewRequest: false,
};

Chatbox.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.object).isRequired,
  messagesRef: PropTypes.any.isRequired,
  onQuickReply: PropTypes.func,
  onSendMessage: PropTypes.func.isRequired,
  inputConfig: PropTypes.object,
  inputDisabled: PropTypes.bool,
  error: PropTypes.string,
  renderEmptyMessage: PropTypes.func,
  loading: PropTypes.bool,
  inputPlaceHolderText: PropTypes.string,
  hasMore: PropTypes.bool,
  isFetchingMore: PropTypes.bool,
  loadMore: PropTypes.func,
  isNewRequest: PropTypes.bool,
};

export default React.memo(Chatbox);
