import { useSubscription } from '@Thread-Magic/thread-service-utils';
import { THREAD_STATES } from 'src/constants';
import { addToMyThreadsCache, removeFromMyThreadsCache } from 'src/graphql/cache';
import {
  ON_CONTACT_THREAD_ADDED,
  ON_CONTACT_THREAD_APPROVAL_REMOVED,
  ON_CONTACT_THREAD_APPROVAL_REQUESTED,
  ON_CONTACT_THREAD_CLOSED,
  ON_CONTACT_THREAD_REMOVED,
  ON_CONTACT_THREAD_REOPENED,
  ON_CONTACT_THREAD_UPDATED,
} from 'src/graphql/subscriptions';
import usePushNotification from 'src/hooks/usePushNotification';
import { getStateInByThreadState } from 'src/utils/normalizer';

export default function useThreadSubscriptions({ onThreadUpdated }) {
  const { handleThreadNotification } = usePushNotification();

  useSubscription(ON_CONTACT_THREAD_ADDED(), {
    onSubscriptionData({ subscriptionData: { data } }) {
      const { thread } = data.onContactThreadAdded;
      handleThreadNotification(thread);
      addToMyThreadsCache({
        threadToAdd: thread,
        variables: {
          stateIn: getStateInByThreadState(thread.state),
        },
        excludeVariables: {
          hasPendingApproval: true,
        },
      });
    },
  });

  useSubscription(ON_CONTACT_THREAD_UPDATED(), {
    onSubscriptionData({ subscriptionData: { data } }) {
      const { thread, delta } = data.onThreadUpdated;
      const previousState = delta?.before?.find((el) => el.name === 'state')?.value;
      const nextState = delta?.after?.find((el) => el.name === 'state')?.value;
      if (previousState && nextState) {
        addToMyThreadsCache({
          threadToAdd: thread,
          variables: {
            stateIn: getStateInByThreadState(thread.state),
          },
          excludeVariables: {
            hasPendingApproval: true,
          },
        });
        removeFromMyThreadsCache({
          threadToRemove: { ...thread, state: previousState },
          variables: {
            stateIn: getStateInByThreadState(previousState),
          },
          excludeVariables: {
            hasPendingApproval: true,
          },
        });
      }

      onThreadUpdated(thread);
    },
  });

  useSubscription(ON_CONTACT_THREAD_REMOVED(), {
    onSubscriptionData({ subscriptionData: { data } }) {
      const { thread } = data.onContactThreadRemoved;
      removeFromMyThreadsCache({
        threadToRemove: thread,
        variables: {
          stateIn: getStateInByThreadState(thread.state),
        },
        excludeVariables: {
          hasPendingApproval: true,
        },
      });
    },
  });

  useSubscription(ON_CONTACT_THREAD_CLOSED(), {
    onSubscriptionData({ subscriptionData: { data } }) {
      const { thread } = data.onContactThreadClosed;
      const previousThreadState = THREAD_STATES.ACTIVE;
      addToMyThreadsCache({
        threadToAdd: thread,
        variables: {
          stateIn: getStateInByThreadState(thread.state),
        },
        excludeVariables: {
          hasPendingApproval: true,
        },
      });
      removeFromMyThreadsCache({
        threadToRemove: { ...thread, state: previousThreadState },
        variables: {
          stateIn: getStateInByThreadState(previousThreadState),
        },
        excludeVariables: {
          hasPendingApproval: true,
        },
      });
    },
  });

  useSubscription(ON_CONTACT_THREAD_REOPENED(), {
    onSubscriptionData({ subscriptionData: { data } }) {
      const { thread } = data.onContactThreadReopened;
      const previousThreadState = THREAD_STATES.DONE;

      addToMyThreadsCache({
        threadToAdd: thread,
        variables: {
          stateIn: getStateInByThreadState(thread.state),
        },
        excludeVariables: {
          hasPendingApproval: true,
        },
      });
      removeFromMyThreadsCache({
        threadToRemove: { ...thread, state: previousThreadState },
        variables: {
          stateIn: getStateInByThreadState(previousThreadState),
        },
        excludeVariables: {
          hasPendingApproval: true,
        },
      });
    },
  });

  useSubscription(ON_CONTACT_THREAD_APPROVAL_REQUESTED(), {
    onSubscriptionData({ subscriptionData: { data } }) {
      const { thread } = data.onContactThreadApprovalRequested;
      addToMyThreadsCache({
        threadToAdd: thread,
        variables: {
          hasPendingApproval: true,
        },
      });
    },
  });

  useSubscription(ON_CONTACT_THREAD_APPROVAL_REMOVED(), {
    onSubscriptionData({ subscriptionData: { data } }) {
      const { thread } = data.onContactThreadApprovalStatusChanged;
      removeFromMyThreadsCache({
        threadToRemove: thread,
        variables: {
          hasPendingApproval: true,
        },
      });
    },
  });
}
